var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_vm._m(1),_c('el-divider'),_vm._m(2),_c('el-divider'),_c('div',{staticClass:"total"},[_c('h4',{staticClass:"title"},[_vm._v(" Score Breakdown   "),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showAnswer),expression:"!showAnswer"}],attrs:{"size":"small","type":"success"},on:{"click":function($event){_vm.showAnswer = true}}},[_vm._v(" Show Answers ")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAnswer),expression:"showAnswer"}],attrs:{"size":"small","type":"danger"},on:{"click":function($event){_vm.showAnswer = false}}},[_vm._v(" Hide Answers ")])],1),_vm._m(3)]),_c('el-divider'),_c('div',{staticClass:"total"},[_c('h4',{staticClass:"title"},[_vm._v(" Prediction Scores ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-sm-12"},[_vm._m(4),_c('h6',[_c('p',{staticClass:"prediction-score"},[_vm._v(" SAT prediction grade "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Based on this test, you are expected to get a score within this range on the real SAT.","placement":"top"}},[_c('el-button',{attrs:{"type":"text"}},[_c('i',{staticClass:"far fa-question-circle fa-sm"})])],1)],1)])]),_c('div',{staticClass:"col-md-6 col-sm-12"},[_vm._m(5),_c('h6',[_c('p',{staticClass:"prediction-score"},[_vm._v(" ACT prediction grade "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Based on this test, you are expected to get a score within this range on the real ACT.","placement":"top"}},[_c('el-button',{attrs:{"type":"text"}},[_c('i',{staticClass:"far fa-question-circle fa-sm"})])],1)],1)])])])]),_c('el-divider')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row sat"},[_c('div',{staticClass:"col-md-5 sat_left print_div text-success"},[_c('div',[_c('p',[_vm._v("ACT")]),_c('span',[_c('i',{staticClass:"fa fa-registered",attrs:{"aria-hidden":"true"}})])])]),_c('div',{staticClass:"col-md-7 sat_right row d-flex"},[_c('div',[_c('ul',[_c('li',[_vm._v(" Name: Wei Wei ")]),_c('li',[_vm._v(" Test Name: "),_c('span',[_vm._v("ACT ")])]),_c('li',[_vm._v(" Test Date: - ")])])]),_c('div',{staticClass:"text-success",staticStyle:{"line-height":"93px","font-size":"50px","font-weight":"700"}},[_vm._v(" X Mocks ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row total"},[_c('div',{staticClass:"col-md-6 col-sm-12 total_score print_div"},[_c('h4',{staticClass:"title"},[_vm._v(" Your Composite Score ")]),_c('div',{staticClass:"total_above row"},[_c('p',{staticClass:"col-md-8 score"},[_vm._v(" 36 ")]),_c('p',{staticClass:"col-sm-4 t_score"},[_vm._v("0 to 36")])]),_c('h6',[_vm._v("Your total Score")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"total"},[_c('h4',{staticClass:"title"},[_vm._v(" Section Scores ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-sm-12 total_score print_div"},[_c('div',{staticClass:"total_above row"},[_c('p',{staticClass:"col-md-8 score"},[_vm._v(" 36 ")]),_c('p',{staticClass:"col-sm-4 t_score"},[_vm._v("0 to 36")])]),_c('h6',[_vm._v("Your English Score")])]),_c('div',{staticClass:"col-md-6 col-sm-12 total_score print_div"},[_c('div',{staticClass:"total_above row"},[_c('p',{staticClass:"col-md-8 score"},[_vm._v(" 36 ")]),_c('p',{staticClass:"col-sm-4 t_score"},[_vm._v("0 to 36")])]),_c('h6',[_vm._v("Your Math Score")])]),_c('div',{staticClass:"col-md-6 col-sm-12 total_score print_div"},[_c('div',{staticClass:"total_above row"},[_c('p',{staticClass:"col-md-8 score"},[_vm._v(" 36 ")]),_c('p',{staticClass:"col-sm-4 t_score"},[_vm._v("0 to 36")])]),_c('h6',[_vm._v("Your Reading Score")])]),_c('div',{staticClass:"col-md-6 col-sm-12 total_score print_div"},[_c('div',{staticClass:"total_above row"},[_c('p',{staticClass:"col-md-8 score"},[_vm._v(" 36 ")]),_c('p',{staticClass:"col-sm-4 t_score"},[_vm._v("0 to 36")])]),_c('h6',[_vm._v("Your Science Score")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('table',{staticClass:"table table-bordered",attrs:{"id":"testresult"}},[_c('thead',[_c('tr',[_c('td',{staticClass:"green-td"}),_c('th',{staticClass:"green-td right-border",attrs:{"colspan":"2"}},[_c('b',[_vm._v("English")])]),_c('th',{staticClass:"green-td right-border",attrs:{"colspan":"2"}},[_c('b',[_vm._v("Math")])]),_c('th',{staticClass:"green-td right-border",attrs:{"colspan":"2"}},[_c('b',[_vm._v("Reading")])]),_c('th',{staticClass:"green-td right-border",attrs:{"colspan":"2"}},[_c('b',[_vm._v("Science")])])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"green-td text-center"},[_vm._v("#")]),_c('td',{staticClass:"right-border text-center",attrs:{"colspan":"2"}},[_c('b',[_vm._v("Section 1")])]),_c('td',{staticClass:"right-border text-center",attrs:{"colspan":"2"}},[_c('b',[_vm._v("Section 2")])]),_c('td',{staticClass:"right-border text-center",attrs:{"colspan":"2"}},[_c('b',[_vm._v("Section 3")])]),_c('td',{staticClass:"right-border text-center",attrs:{"colspan":"2"}},[_c('b',[_vm._v("Section 4")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"total_above row"},[_c('p',{staticClass:"col-md-8 score"},[_vm._v(" 1550 - 1600 ")]),_c('p',{staticClass:"col-sm-4 t_score"},[_vm._v("400 to 1600")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"total_above row"},[_c('p',{staticClass:"col-md-8 score"},[_vm._v(" 35 - 36 ")]),_c('p',{staticClass:"col-sm-4 t_score"},[_vm._v("0 to 36")])])
}]

export { render, staticRenderFns }